import "./jquery.subtle-slideshow";
import "./lightslider";
import "./pagination";

new WOW().init();
// filterItemsByQuery();


/* Add data-toggle=dropdown only for mobile */
$(document).ready(function () {
  $(window).resize(function () {
    if ($(window).width() >= 992) {
      // $('[data-toggle="dropdown"]');
      $(".dropdown-toggle").removeAttr("data-toggle", "dropdown");
    }
  });

  /* Scroll to developments area if Search activated */
  if (window.location.href.indexOf('?status=') || window.location.href.indexOf('?type=') > 0) {
    console.log('boom');
    $('html, body').animate({ scrollTop: $('#development-types').offset().top }, 800);
  }
});
